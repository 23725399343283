import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import * as ROUTES from '@constants/routes';
import SessionContext from '@context/session';
import { Link } from 'react-router-dom';

const Container = styled.div`
  min-height: 56px;
  width: 100%;
  background: #000639;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  @media (max-width: 768px) {
    padding: 0px 20px;
    .account-title{
      display: none;
    }
  }
  a{
    color: white;
    .anticon-user{
      margin-right: 5px;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: white;
`;

const Navigation = () => {
  const session = React.useContext(SessionContext);

  return (
    <Container>
      <Link to={ROUTES.INDEX}>
        <Title>Conversion Bear Partners</Title>
      </Link>

      {session && 
        <Link to={ROUTES.ACCOUNT}>
          <Icon type="user" />
          <span className="account-title">Account</span>
        </Link>
      }
    </Container>
  );
};

export default Navigation;
