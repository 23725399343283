import React, { Component } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  height: 58px;
  border: 2px solid #5D6CC1;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  width: 100%;
`;

interface InputProps {
  required?: boolean ,
  type?: string ,
  name?: string ,
  value?: string ,
  placeholder?: string,
  id?: string,
  onChange?: any,
}

class InputField extends Component<InputProps> {
  static defaultProps: any = {
    required: false,
    id: '',
    type: 'text',
    placeholder: 'text',
    name: '',
    value: '',
    onChange: () => {}
  }

  render() {
    return (
      <Input {...this.props} />
    );
  }
}


export default InputField;
