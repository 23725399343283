import React, {useState} from 'react';

const SessionContext = React.createContext({
  session: null,
  setSession: (data?: any) => {},
});

export const Provider = (props: any) => {
  const [session, setSession] = useState(null);
  return <SessionContext.Provider
    value={{
      session,
      setSession,
      // setSession: (data) => {setSession(data)}
    }}
  >
    {props.children}
  </SessionContext.Provider>;
}

export default SessionContext;



