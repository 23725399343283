import React,{useEffect} from 'react';
import styled from 'styled-components';
import { Card, Layout as AntdLayout } from 'antd';

import { Session } from '@typeDefs/session';
import Layout from '@components/Layout';

import PasswordChangeForm from './PasswordChangeForm';

const StyledContent = styled(AntdLayout.Content)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 56px;
`;

const StyledCard = styled(Card)`
  min-width: 200px;
  max-width: 400px;
`;

const PasswordChangePage = () => {

  useEffect(() => {    
      amplitude.logEvent('page_load',{value: 'password_change'});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Layout noFooter>
      <StyledContent>
        <StyledCard title="Change your password">
          <PasswordChangeForm />
        </StyledCard>
      </StyledContent>
    </Layout>
  );
};


export default PasswordChangePage;
