import styled from 'styled-components';
import { Button } from 'antd';

const StretchedButton = styled(Button)`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

export default StretchedButton;
