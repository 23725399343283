import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  padding: 30px 40px 40px 40px;
  background: white;
  @media (max-width: 768px) {
    padding: 30px 20px 40px 20px;
  }
`
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #212B36;
  margin-bottom: 30px;
`
const Block = styled.div`
  box-shadow: 0px 0px 10px rgba(196, 205, 213, 0.6);
  border-radius: 3px;
  padding: 20px;
  .title{
    color: white;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  .text{
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: white;
    &.p20{
      margin-bottom: 20px;
    }
  }
`;

class ExclusiveOneTimeOffers extends Component {
  render() {
    return (
      <Container>
        <Title>Special One Time Bonuses</Title>
        <Row>
          <Col xs={24} md={10}>
            <Block style={{background: 'linear-gradient(134.75deg, #5142F9 0.83%, #1933BE 100%)'}}>
              <div className="title">$100</div>
              <div className="text p20">2 minute testimonial video</div>
              <div className="text p20">Record a video in selfie-mode and answer these questions:</div>
              <div className="text p20">
                <span role="img" aria-label="Finger">☝️</span> Why are you using post purchase funnels?<br/>
                <span role="img" aria-label="Finger">☝️</span> Was Honeycomb easy to set up?<br/>
                <span role="img" aria-label="Finger">☝️</span> What was the app impact on your revenue?
              </div>
              <div className="text p20">Attach a screenshot of your Honeycomb performance dashboard.</div>
              <div className="text p20">Email the video and screen shot to jonathan@conversionbear.com</div>
            </Block>
          </Col>
        </Row>
      </Container>
    )
  };
}

export default ExclusiveOneTimeOffers;