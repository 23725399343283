import React from 'react';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

// import * as ROUTES from '@constants/routes';
import FormItem from '@components/Form/Item';
// import FormStretchedButton from '@components/Form/StretchedButton';
import FormAtomButton from '@components/Form/AtomButton';
import useErrorIndicator from '@hooks/useErrorIndicator';
import { useUpdateAccount } from 'src/requests';

const Container = styled.div`
  padding: 20px 40px 50px 40px;
  background: white;
  @media (max-width: 768px) {
    padding: 20px 20px 50px 20px;
  }
  &.ant-form-item-required {
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: #212B36;
    margin-bottom: 12px;
  }
  .ant-form {
    max-width: 510px;
    .ant-btn {
      height: 58px;
      background: #5D6CC1;
      border-radius: 3px;
      padding: 19px 24px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      border: none;
      cursor: pointer;
    }
    .ant-input{
      height: 58px;
      border: 2px solid #5D6CC1;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 20px;
      width: 100%;
    }
    .ant-form-item-required{
      font-weight: 500;
      font-size: 17px;
      line-height: 17px;
      color: #212B36;
      margin-bottom: 12px;
      ::before{
        display: none;
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #212B36;
  margin-bottom: 30px;
`;

interface PayoutDetailsProps extends FormComponentProps {
  onSuccess: () => void;
  account: any;
  loading: boolean;
  // onNavigateSignUp?: () => void;
  // onNavigatePasswordForgot?: () => void;
}

const PayoutDetailsForm = ({
  form,
  onSuccess,
  account,
  loading,
  // onNavigateSignUp,
  // onNavigatePasswordForgot,
}: PayoutDetailsProps) => {
  // const history = useHistory();

  const [updateAccount, { loading: loadingUpdateAccount, error: errorPayouts, /*  data: account */ }] = useUpdateAccount();

  useErrorIndicator({ error: errorPayouts });
/*
  const handleNavigateSignUp = onNavigateSignUp
    ? onNavigateSignUp
    : () => {
      history.push(ROUTES.SIGN_UP)
    };
*/
/*
  const handleNavigatePasswordForgot = onNavigatePasswordForgot
    ? onNavigatePasswordForgot
    : () => {
      history.push(ROUTES.PASSWORD_FORGOT);
    }
*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    form.validateFields(async (error, values) => {
      if (error) return;

      try {
        await updateAccount({
          full_name: values.full_name,
          email: values.email,
          paypal_account: values.paypal_account,
        });

        onSuccess();
      } catch (error) {}
    });

    event.preventDefault();
    amplitude.logEvent('click-save_account_edit');
  };
  
  return (<Container>
    <Title>Payout details</Title>
    <Form onSubmit={handleSubmit}>
      <FormItem label="Full Name">
        {form.getFieldDecorator('full_name', {
          rules: [
            {
              required: true,
              message: 'Please input your full name!',
            },
          ],
          validateTrigger: 'onBlur',
          initialValue: account.full_name,
        })(
          <Input
            placeholder="ex: John Doe"
            // aria-label="sign-in-email"
          />
        )}
      </FormItem>
      <FormItem label="Email">
        {form.getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid email!',
            },
            {
              required: true,
              message: 'Please input your email!',
            },
          ],
          validateTrigger: 'onBlur',
          initialValue: account.email,
        })(
          <Input
            placeholder="ex: johndoe@gmail.com"
            // aria-label="sign-in-email"
          />
        )}
      </FormItem>
      <FormItem label="Paypal Account">
        {form.getFieldDecorator('paypal_account', {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid paypal account!',
            },
            {
              required: true,
              message: 'Please input your paypal account!',
            },
          ],
          validateTrigger: 'onBlur',
          initialValue: account.paypal_account,
        })(
          <Input
            placeholder="ex: johndoe@gmail.com"
            // aria-label="sign-in-email"
          />
        )}
      </FormItem>

      <FormItem>
        <FormAtomButton
          loading={loadingUpdateAccount}
          type="primary"
          htmlType="submit"
          aria-label="sign-in-submit"
        >
          Save
        </FormAtomButton>
      </FormItem>
    </Form>
  </Container>);
};

export default Form.create<PayoutDetailsProps>({
  name: 'payout-details',
})(PayoutDetailsForm);
