import * as ROUTES from '@constants/routes';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import SessionContext from '@context/session';

export default () => {
  const history = useHistory();
  const { setSession } = useContext(SessionContext);
  return () => {
    history.push(ROUTES.SIGN_IN);
    setSession(false);
    localStorage.removeItem('token');
  }
};
