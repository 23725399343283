
import React, { useEffect } from 'react';
// import { NextPage } from 'next';
import styled from 'styled-components';
// import { User, Payout, Performance as PerformanceData } from '@generated/client';
// import { GET_ME } from '@queries/user';
// import { Session } from '@typeDefs/session';
import { Layout as AntdLayout } from 'antd';
import Layout from '@components/Layout';
import WelcomePartner from './components/WelcomePartner';
import CommissionRewards from './components/CommissionRewards';
import SpecialOneTimeBonuses from './components/SpecialOneTimeBonuses';
import ExclusiveOneTimeOffers from './components/ExclusiveOneTimeOffers';
import YourAffiliateLinks from './components/YourAffiliateLinks';
import MarketingMaterials from './components/MarketingMaterials';
import UsefulLinks from './components/UsefulLinks';
import Performance from './components/Performance';
import Payouts from './components/Payouts';
import PayoutDetails from './components/PayoutDetails';

import { useGetPayouts, useGetAccount, useGetPerformance } from '../../requests';

// import * as CRC32 from 'node-crc';

const StyledContent = styled(AntdLayout.Content)`
  margin: calc(56px + 0px) 0px 0px;
`;

interface HomePageProps {
  data: {
    me: any; // User;
  };
  userAgent: string|undefined,
}

// type NextAuthPage = NextPage<HomePageProps> & {
//   isAuthorized: (session: Session) => boolean;
// };

// const HomePage: NextAuthPage = ({ data, account, payouts, performance, userAgent }) => {
  // Property 'loading' does not exist on type '((shopId: string) => Promise<IPayout[] | null>) | { loading: any; error: any; }
const HomePage = ({ userAgent }: any) => {
  const [getPayouts, { loading: loadingPayouts, /* error: errorPayouts, */ data: payouts }] = useGetPayouts();
  const [getAccount, { loading: loadingAccount, /* error: errorPayouts, */ data: account }] = useGetAccount();
  const [getPerfomance, { loading: loadingPerfomance, /* error: errorPayouts, */ data: performance }] = useGetPerformance();
  useEffect(() => {    
    (async () => {
      await Promise.all([
        getPayouts(),
        getAccount(),
        getPerfomance(),
      ]);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {    
    if(!loadingAccount){
      amplitude.getInstance().setUserId(account?.email);
      amplitude.setUserProperties({ appName: "partners" });
      amplitude.logEvent('page_load',{value: 'main'});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAccount]);

  return <Layout>
    <StyledContent>
      <WelcomePartner /> 
      <CommissionRewards />
      <SpecialOneTimeBonuses />
      <ExclusiveOneTimeOffers />
      <YourAffiliateLinks account={account || {}} />
      <MarketingMaterials />
      <UsefulLinks />
      <Performance performance={performance || []} userAgent={userAgent} loading={loadingPerfomance} />
      <Payouts
        payouts={payouts || []}
        userAgent={userAgent}
        loading={loadingPayouts}
      />
      <PayoutDetails
        account={account || {}}
        loading={loadingAccount}
        onSuccess={() => null}
      />
    </StyledContent>
  </Layout>
  ;
};

// HomePage.isAuthorized = (session: Session) => !!session;

export default HomePage;