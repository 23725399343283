import React, { Component } from 'react';
import styled from 'styled-components';
import InputField from '@components/Input';
import ButtonCustom from '@components/Button';

const Container = styled.div`
  padding: 50px 40px 30px 40px;
  background: #F4F6F8;
  @media (max-width: 768px) {
    padding: 50px 20px 30px 20px;
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #212B36;
  margin-bottom: 30px;
`

const Label = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  color: #212B36;
  margin-bottom: 12px;
`;

const BlockField = styled.div`
  margin-bottom: 30px;
  display: flex;
  input{
    max-width: 300px;
  }
  button{
    margin-left: 20px;
    display: flex;
    align-items: center;
    img{
      margin-right: 4px;
    }
  }
  @media (max-width: 768px) {
    button{
      margin-top: 20px;
      margin-left: 0px;
    }
    input{
      max-width: 100%;
    }
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface YourAffiliateLinksProps{
  account: any
}

class YourAffiliateLinks extends Component<YourAffiliateLinksProps> {

  copyLink = (id: string) => {
    const copyText: any = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    amplitude.logEvent('click-copy_link');
  }

  render() {
    const { account }: any = this.props;
    let tr_7: string = account.tokenTrial7;
    let tr_14: string = account.tokenTrial14;
    let tr_30: string = account.tokenTrial30;
    return (
      <Container>
        <Title>Your affiliate links</Title>
        <Label>7 days trial (collection time: up to 37 days)</Label>
        <BlockField>
          <InputField value={tr_7} id="tr_7" placeholder="upsell.ink/XkEfg" />
          <ButtonCustom onClick={() => this.copyLink('tr_7')}><img src='/images/Link.svg' alt="" /> Copy Link</ButtonCustom>
        </BlockField>
        <Label>14 days extended trial (collection time: up to 44 days)</Label>
        <BlockField>
          <InputField value={tr_14} id="tr_14" placeholder="upsell.ink/rfvcx" />
          <ButtonCustom onClick={() => this.copyLink('tr_14')}><img src='/images/Link.svg' alt="" /> Copy Link</ButtonCustom>
        </BlockField>
        <Label>30 days extended trial (collection time: up to 60 days)</Label>
        <BlockField>
          <InputField value={tr_30} id="tr_30" placeholder="upsell.ink/vfdxx" />
          <ButtonCustom onClick={() => this.copyLink('tr_30')}><img src='/images/Link.svg' alt="" /> Copy Link</ButtonCustom>
        </BlockField>
      </Container>
    );
  }
}

export default YourAffiliateLinks;
