import React,{useEffect} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Card, Layout as AntdLayout } from 'antd';

import * as ROUTES from '@constants/routes';
import Layout from '@components/Layout';

import SignInForm from './SignInForm';

const StyledContent = styled(AntdLayout.Content)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 56px;
`;

const StyledCard = styled(Card)`
  min-width: 200px;
  max-width: 400px;
  .ant-card-head-title{
    color: #212B36;
    font-size: 17px;
  }
`;

const SignInPage = () => {
  const history = useHistory();

  useEffect(() => {    
    amplitude.logEvent('page_load',{value: 'sign_in'});
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const handleSuccess = () => {
    history.push(ROUTES.INDEX);
  };

  return (
    <Layout noFooter>
      <StyledContent>
        <StyledCard title="Sign in to your partner account">
          <SignInForm onSuccess={handleSuccess} />
        </StyledCard>
      </StyledContent>
    </Layout>
  );
};

export default SignInPage;
