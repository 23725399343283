import React,{useEffect} from 'react';
import { useGetAccount } from 'src/requests';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Head from '@components/Head';
import 'antd/dist/antd.css';

const TIMEOUT = 400;

const theme = {
  colors: {
    primary: '#5D6CC1',
    lightGrey: '#E5E5E5',
  },
};

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;

    font-family: 'Poppins', sans-serif;

    font-size: 16px;
    letter-spacing: -0.003em;
    line-height: 1.58;
  }

  a {
    color: #5C6AC4;
    &:hover{
      color: #5C6AC4;
    }
  }

  .ant-layout-header{
    z-index: 99;
  }
  .page-transition-enter {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  .page-transition-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
  }

  .page-transition-exit {
    opacity: 1;
  }

  .page-transition-exit-active {
    opacity: 0;
    transition: opacity ${TIMEOUT}ms;
  }

  .loading-indicator-appear,
  .loading-indicator-enter {
    opacity: 0;
  }

  .loading-indicator-appear-active,
  .loading-indicator-enter-active {
    opacity: 1;
    transition: opacity ${TIMEOUT}ms;
  }

  pre:not(.prism-code) {
    background-color: #2f1e2e !important;
    border-radius: 4px;
    border-left: 4px solid #823eb7;
  }

  code {
    white-space: pre-wrap !important;
  }

  .token-line {
    line-height: 1.15 !important;
  }

  .highlight-line {
    background-color: #4f424c;
    display: block;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

interface SignUpFormProps {
  Component: any;
}

const App = ({ Component }: SignUpFormProps) => {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Head />
      <Component />
    </ThemeProvider>
  );
}

export default App;
