import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import * as ROUTES from '@constants/routes';
import FormItem from '@components/Form/Item';
import FormStretchedButton from '@components/Form/StretchedButton';
import FormAtomButton from '@components/Form/AtomButton';
import useErrorIndicator from '@hooks/useErrorIndicator';
import { useSignUp } from 'src/requests';
import { useHistory } from 'react-router-dom';
import SessionContext from '@context/session';

interface SignUpFormProps extends FormComponentProps {
  onSuccess: () => void;
  onNavigateSignIn?: () => void;
}

const SignUpForm = ({
  form,
  onSuccess,
  onNavigateSignIn,
}: SignUpFormProps) => {
  const history = useHistory();
  const { setSession } = useContext(SessionContext);  

  const [signUp, { loading, error }] = useSignUp();

  useErrorIndicator({ error });

  const handleNavigateSignIn = onNavigateSignIn
    ? onNavigateSignIn
    : () => {
      history.push(ROUTES.SIGN_IN);
    }

  const [
    confirmPasswordDirty,
    setConfirmPasswordDirty,
  ] = React.useState(false);

  const handleConfirmBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPasswordDirty(
      confirmPasswordDirty || !!event.target.value
    );
  };

  const compareToFirstPassword = (
    _: any,
    value: any,
    callback: any
  ) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Your passwords are different.');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (
    _: any,
    value: any,
    callback: any
  ) => {
    if (value && confirmPasswordDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    form.validateFields(async (error, values) => {
      if (error) return;

      try {
        const data = await signUp({
          username: values.username,
          email: values.email,
          password: values.password,
        });

        localStorage.setItem('token', data?.token || '');

        if (data?.token) {
          setSession(data?.token);
        }

        onSuccess();
      } catch (error) {}
    });

    event.preventDefault();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <FormItem label={<span>Full name</span>}>
        {form.getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: 'Please input your full name!',
              whitespace: true,
            },
          ],
          validateFirst: true,
          validateTrigger: 'onBlur',
        })(<Input aria-label="sign-up-username" />)}
      </FormItem>

      <FormItem label="E-mail">
        {form.getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid email!',
            },
            {
              required: true,
              message: 'Please input your email!',
            },
          ],
          validateFirst: true,
          validateTrigger: 'onBlur',
        })(<Input aria-label="sign-up-email" />)}
      </FormItem>

      <FormItem label="Password" hasFeedback>
        {form.getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              min: 6,
              message: 'Your password is too short.',
            },
            {
              validator: validateToNextPassword,
            },
          ],
          validateFirst: true,
          validateTrigger: 'onBlur',
        })(<Input.Password autoComplete="new-password" aria-label="sign-up-password" />)}
      </FormItem>

      <FormItem label="Confirm Password" hasFeedback>
        {form.getFieldDecorator('confirm', {
          rules: [
            {
              required: true,
              message: 'Please confirm your password!',
            },
            {
              min: 6,
              message: 'Your password is too short.',
            },
            {
              validator: compareToFirstPassword,
            },
          ],
          validateFirst: true,
          validateTrigger: 'onBlur',
        })(
          <Input.Password
            onBlur={handleConfirmBlur}
            autoComplete="new-password"
            aria-label="sign-up-password-confirm"
          />
        )}
      </FormItem>

      <FormItem wrapperCol={{ sm: 24 }}>
        <FormStretchedButton
          loading={loading}
          type="primary"
          htmlType="submit"
          aria-label="sign-up-submit"
        >
          Sign Up
        </FormStretchedButton>

        <>
          <FormAtomButton
            type="link"
            onClick={handleNavigateSignIn}
            aria-label="sign-in-link"
          >
            Already have an account? Sign In
          </FormAtomButton>
        </>
      </FormItem>
    </Form>
  );
};

export default Form.create<SignUpFormProps>({
  name: 'sign-up',
})(SignUpForm);
