import React from 'react';
import styled from 'styled-components';
import { Layout as AntdLayout, Row, Col } from 'antd';
import Navigation from '@components/Navigation';

const { Header: AntdHeader, Footer: AntdFooter } = AntdLayout;


const StyledAntdLayout = styled(AntdLayout)`

  display: flex;
  flex-direction: row;

  div {
    flex: 1;
  }
`;

const StyledAntdFooter = styled(AntdFooter)`
  background: #000639;
  min-height: 80px;
`;

type LayoutProps = {
  noFooter?: boolean;
  children: React.ReactNode;
};

const LinkFooter = styled.a`
  color: white;
  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

const Footer = () => (
  <StyledAntdFooter>
   <Row>
     <Col xs={24} md={6}><span style={{color: 'white'}}>Questions? email us directly</span></Col>
     <Col xs={24} md={6}><LinkFooter href="mailto: lynda@conversionbear.com">lynda@conversionbear.com</LinkFooter></Col>
     <Col xs={24} md={6}><LinkFooter href="mailto: jonathan@conversionbear.com">jonathan@conversionbear.com</LinkFooter></Col>
   </Row>
  </StyledAntdFooter>
);

const Layout = ({ noFooter = false, children }: LayoutProps) => (
  <AntdLayout style={{minHeight: !noFooter ? '100vh' : '100vh'}}>
    <AntdHeader
      style={{
        position: 'fixed',
        width: '100%',
        lineHeight: 1,
        height: '56px',
        padding: 0,
        margin: 0,
      }}
    >
      <Navigation />
    </AntdHeader>
    <StyledAntdLayout>{children}</StyledAntdLayout>
    {!noFooter && <Footer />}
  </AntdLayout>
);

export { Footer };

export default Layout;
