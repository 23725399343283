import React, { Component } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  height: 58px;
  background: #5D6CC1;
  border-radius: 3px;
  padding: 19px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
`;

interface ButtonProps{
  onClick?: any
}

class ButtonCustom extends Component<ButtonProps> {
  render() {
    return (
      <Button {...this.props}>{this.props.children}</Button>
    );
  }
}

export default ButtonCustom;
