import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 30px 40px 10px 40px;
  background: white;
  @media (max-width: 768px) {
    padding: 30px 20px 0px 20px;
  }
`
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #212B36;
  margin-bottom: 30px;
`
const Block = styled.div`
  box-shadow: 0px 0px 10px rgba(196, 205, 213, 0.6);
  border-radius: 3px;
  padding: 20px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  .title{
    color: white;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  .text{
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: white;
  }
`;

const Item = styled.div`
  max-width: 204px;
  margin: 0 20px;
`

const Items = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  margin-right: -20px;
`;

class SpecialOneTimeBonuses extends Component {
  render() {
    return (
      <Container>
        <Title>Special One Time Bonuses</Title>
        <Items>
          <Item>
            <Block style={{background: 'linear-gradient(122.49deg, #E220E6 1.05%, #AB00FB 100%)'}}>
              <div className="title">$100</div>
              <div className="text">bonus for every 10 stores</div>
            </Block>
          </Item>
          <Item>
            <Block style={{background: 'linear-gradient(122.49deg, #E220E6 1.05%, #AB00FB 100%)'}}>
              <div className="title">$1000</div>
              <div className="text">bonus for every 50 stores</div>
            </Block>
          </Item>
          
        </Items>
      </Container>
    )
  };
}

export default SpecialOneTimeBonuses;