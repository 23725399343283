import React from 'react';
// import Head from 'next/head';
// helmet???

export default () => (
  <React.Fragment />
  // <Head>
  //   <title>Conversion Bear Partners</title>
  //   <meta name="description" content="Conversion Bear Partners" />
  //   <meta name="robots" content="all,follow" />
  //   <meta name="googlebot" content="index,follow,snippet,archive" />
  //   <meta
  //     name="viewport"
  //     content="width=device-width, initial-scale=1.0"
  //   />
  //   <meta itemProp="name" content="Conversion Bear Partners" />
  //   <meta itemProp="description" content="Conversion Bear Partners" />
  //   <meta itemProp="image" content="/facebook.png" />
  //   <meta name="twitter:card" content="summary" />
  //   <meta name="twitter:site" content="@conversion_bear_partners" />
  //   <meta name="twitter:title" content="Conversion Bear Partners" />
  //   <meta
  //     name="twitter:description"
  //     content="Conversion Bear Partners"
  //   />
  //   <meta name="twitter:image:src" content="/twitter.png" />
  //   <meta name="twitter:image:alt" content="Conversion Bear Partners" />
  //   <meta name="og:site_name" content="Conversion Bear Partners" />
  //   <meta name="og:type" content="website" />
  //   <meta name="og:title" content="Conversion Bear Partners" />
  //   <meta name="og:description" content="Conversion Bear Partners" />
  //   <meta name="og:image" content="/facebook.png" />
  //   <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" /> 
  //   <link rel="shortcut icon" type="image/png" href="/favicon/favicon.ico"/>
  //   <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
  //   <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
  //   <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
  //   <link rel="manifest" href="/site.webmanifest"/>
  //   <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
  //   <meta name="msapplication-TileColor" content="#00a300"/>
  //   <meta name="theme-color" content="#ffffff"></meta>
  // </Head>
);
