import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 50px 40px 0px 40px;
  background: white;
  @media (max-width: 768px) {
    padding: 50px 20px 0px 20px;
  }
`
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #212B36;
  margin-bottom: 30px;
`
const Block = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  img{
    width: 100%;
    margin-bottom: 29px;
  }
  a{
    width: 100%;  
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
  }
`;

const Items = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Item = styled.div`
  min-width: 300px;
  max-width: 300px;
  margin: 15px;
`;

class MarketingMaterials extends Component {
  render() {
    return (
      <Container>
        <Title>Marketing Materials</Title>
        <Items>
          <Item>
            <Block>
              <img src="/images/banner_3_preview.png" alt="" />
              <div>
                <a href="/images/banner_3.png" target='_blank' download>Download as PNG</a>
              </div>
            </Block>
          </Item>
          <Item>
            <Block>
              <img src="/images/banner_2_preview.png" alt="" />
              <div>
                <a href="/images/banner_2.png" target='_blank' download>Download as PNG</a>
              </div>
            </Block>
          </Item>
          <Item>
            <Block>
              <img src="/images/banner_1_preview.png" alt="" />
              <div>
                <a href="/images/banner_1.png" target='_blank' download>Download as PNG</a>
              </div>
            </Block>
          </Item>
          <Item>
            <Block>
              <img src="/images/video_cover.png" alt="" />
              <div>
                <a href="https://www.youtube.com/watch?v=6z6YuUqnUE4" download target='_blank' rel="noopener noreferrer">Open Video</a>
              </div>
            </Block>
          </Item>
          <Item>
            <Block>
              <img src="/images/partner_preview_logo.png" style={{maxWidth: 180}} alt="" />
              <div>
                <a href="/images/logo_download.png" target='_blank' download>Download as PNG</a>
              </div>
            </Block>
          </Item>
          <Item>
            <Block>
              <img src="/images/partner_badge_dark.png" style={{maxWidth: 264}} alt="" />
              <div>
                <a href="/images/partner_badge_dark.png" target='_blank' download>Download as PNG</a>
              </div>
            </Block>
          </Item>
          <Item>
            <Block>
              <img src="/images/partner_badge_light.png" style={{maxWidth: 264}} alt="" />
              <div>
                <a href="/images/partner_badge_light.png" target='_blank' download>Download as PNG</a>
              </div>
            </Block>
          </Item>
        </Items>
      </Container>
    );
  }
}

export default MarketingMaterials;
