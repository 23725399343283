import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #F4F6F8;
  padding: 30px 40px 18px 40px;
  @media (max-width: 768px) {
    padding: 30px 20px 18px 20px;
  }
  a{
    display: flex;
    align-items: center;
    img{
      margin-right: 12px;
    }
  }
`

const Title = styled.div`
  color: #212B36;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const Text = styled.div`
  font-size: 17px;
  line-height: 1.2;
  color: #212B36;
  max-width: 835px;
  margin-bottom: 10px;
`;

class WelcomePartner extends Component {
  render() {
    return (
      <Container>
        <Title><span role="img" aria-label="Waving Hand">👋</span> Welcome Partner!</Title>
        <Text>
          Let’s help businesses grow faster, together.<br/>
          We offer ongoing comission for each store that uses your unique affiliate link.<br/>
          Once we <strong>collect payement</strong> from the merchant, you will recieve a comission depending on the merchant plan.<br/>
          We will send the money directly to your PayPal account.<br/>
        </Text>
        <a href="https://youtu.be/CKl-kXjRi2Y" target="_blank" rel="noopener noreferrer"><img src="/images/play.svg" alt="" /> Watch partners walk-through</a>
      </Container>
    );
  }
}

export default WelcomePartner;
