import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0px 40px 40px 40px;
  @media (max-width: 768px) {
    padding: 0px 20px 40px 20px;
  }
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a + a {
    margin-top: 22px;
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #212B36;
  margin-bottom: 30px;
`

class UsefulLinks extends Component {
  render() {
    return (
      <Container> 
        <Title>Useful Links</Title>
        <a href="https://apps.shopify.com/honeycomb-upsell-funnels" target="_blank" rel="noopener noreferrer">App Shopify Page <img src="/images/Promote.svg" alt="" /></a>
        <a href="https://www.youtube.com/watch?v=6z6YuUqnUE4"  target="_blank" rel="noopener noreferrer">App Intro Video <img src="/images/Promote.svg" alt="" /></a>
        <a href="https://conversionbear.freshdesk.com/support/solutions/folders/48000665614" target="_blank" rel="noopener noreferrer">Knowledge Base <img src="/images/Promote.svg" alt="" /></a>
      </Container>
    );
  }
}

export default UsefulLinks;
