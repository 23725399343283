/** global REACT_APP_BASE_URL */
import { useState } from 'react';
import { IPayout, IAccount, IPerformance } from '../types/models';

export interface FetchResult<TData = any> {
  data?: TData;
  error?: {
    message?: string;
  };
  loading: boolean;
  // called: boolean;
  // client?: ApolloClient<object>;
}

declare type useFetchType<TInputData = any, TOutputData = any> = [(url: string, method: string, data?: TInputData, fakeResult?: TOutputData) => Promise<TOutputData>, FetchResult<TOutputData>]

declare type useFetchJustDataType<TInputData = any, TOutputData = any> = [(data?: TInputData) => Promise<TOutputData>, FetchResult<TOutputData>]


export function useFetch(): useFetchType {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{message?: string} | undefined>();
  const [resData, setResData] = useState(null);
  // const [resData, setResData] = useState<(value: TData) => [TData, (value: TData) => any]>(null);
  async function request(url: string, method: string|undefined = 'GET', data: any|undefined, fakeResult?: any|undefined) {
    setLoading(true);
    if (fakeResult) {
      setResData(fakeResult);
      setLoading(false);
      return fakeResult;
    }
    try {
      const options: {
        method: string,
        headers: any,
        body?: string;
      } = {
        method: method || 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      if (data) {
        options.body = JSON.stringify(data);
      }
      const token = localStorage.getItem('token');
      if (token) {
        options.headers.Authorization = `Bearer ${token}`;
      }
      const resp = await fetch(process.env.REACT_APP_BASE_URL + url, options);
      let result: any = null;
      try {
        const respData = await resp.json();
        if (resp.status === 200) {
          result = respData;
          setResData(respData);
        } else {
          setError({
            message: respData.message || 'unknown message',
          });
        }
      } catch (err) {
        setError({message: 'unknown error'})
      }
      setLoading(false);
      return result;
    } catch (err) {
      console.log('TCL: request -> err', err);
      setError({message: 'Unknown'})
    }
  };
  return [request, { loading, error, data: resData }]
}

export function useGetPayouts(): useFetchJustDataType<unknown, IPayout[]> {
  const [request, { loading, error, data }] = useFetch();
  return [
    () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const fakeData = [
        {
          userId: '',
          shopify_domain: '',
          date: new Date(),
          payout: 100,
        },
        {
          userId: '',
          shopify_domain: '',
          date: new Date(),
          payout: 300,
        },
      ];
      return request(
        `payouts`,
        'GET',
        undefined
      );
    },
    { loading, error, data }
  ];
}

export function useEmailChange(): useFetchJustDataType {
  const [request, { loading, error }] = useFetch();
  return [(data: {email: string}): Promise<any> => {
    return request('changeEmail', 'POST', data)
  }, { loading, error }];
}

export function useSignIn(): useFetchJustDataType {
  const [request, { loading, error }] = useFetch();
  return [(data: {email: string, password: string}): Promise<any> => {
    return request('signIn', 'POST', data);
  }, { loading, error }];
}

export function useSignUp(): useFetchJustDataType {
  const [request, { loading, error }] = useFetch();
  return [(data: {email: string, password: string, username: string}): Promise<any> => {
    return request('signUp', 'POST', data);
  }, { loading, error }];
}

export function usePasswordChange(): useFetchJustDataType {
  const [request, { loading, error }] = useFetch();
  return [(data: {password: string}): Promise<any> => {
    return request('passwordChange', 'POST', data);
  }, { loading, error }];
}

export function usePasswordForgot(): useFetchJustDataType {
  const [request, { loading, error }] = useFetch();
  return [(data: {email: string}): Promise<any> => {
    return request('passwordForgot', 'POST', data);
  }, { loading, error }];
}

export function useGetAccount(): useFetchJustDataType<unknown, IAccount> {
  const [request, { loading, error, data }] = useFetch();
  return [() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return request('getAccount', 'GET', null);
  }, { loading, error, data }];
}

export function useUpdateAccount(): useFetchJustDataType {
  const [request, { loading, error, data }] = useFetch();
  return [(_data: {
    full_name: string,
    email: string,
    paypal_account: string,
  }): Promise<any> => {
    return request('updateAccount', 'POST', _data);
  }, { loading, error, data }];
}

export function useGetPerformance(): useFetchJustDataType<unknown, IPerformance[]> {
  const [request, { loading, error, data }] = useFetch();
  return [(): Promise<any> => {
    return request('perfomance', 'GET', null);
  }, { loading, error, data }];
}

