import React,{useEffect} from 'react';
import styled from 'styled-components';
import { Card, Layout as AntdLayout } from 'antd';

import * as ROUTES from '@constants/routes';
import { Session } from '@typeDefs/session';
import Layout from '@components/Layout';

import SignUpForm from './SignUpForm';
import { useHistory } from 'react-router-dom';

const StyledContent = styled(AntdLayout.Content)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 56px;
`;

const StyledCard = styled(Card)`
  min-width: 200px;
  max-width: 400px;
  .ant-card-head-title{
    color: #212B36;
    font-size: 17px;
  }
`;

const SignUpPage = () => {
  const history = useHistory();


  React.useEffect(() => {
    // router.prefetch(ROUTES.INDEX);
  });
  useEffect(() => {    
    amplitude.logEvent('page_load',{value: 'sign_up'});
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  const handleSuccess = () => {
    history.push(ROUTES.INDEX);
  };

  return (
    <Layout noFooter>
      <StyledContent>
        <StyledCard title="Sign up to get started">
          <SignUpForm onSuccess={handleSuccess} />
        </StyledCard>
      </StyledContent>
    </Layout>
  );
};

SignUpPage.isAuthorized = (session: Session) => true;

export default SignUpPage;
