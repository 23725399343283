import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Col, Row, Layout as AntdLayout } from 'antd';

import * as ROUTES from '@constants/routes';
import Layout from '@components/Layout';

import { useGetAccount } from 'src/requests';
import useSignOut from '@hooks/useSignOut';
import { Link } from 'react-router-dom';

const StyledContent = styled(AntdLayout.Content)`
  margin: calc(56px + 32px) 32px 32px;
`;

interface AccountPageProps {
  data: {
    me: any; // User;
  };
}

const AccountPage = ({ data }: any) => {
  const signOut = useSignOut();
  const [getAccount, { data: account }] = useGetAccount();
  
  useEffect(() => {
    (async () => {
      await Promise.all([
        getAccount(),
      ]);
    })();  
    amplitude.logEvent('page_load',{value: 'account'});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const me = account || {
    userId: '',
    full_name: '',
    email: '',
  };

  return (
    <Layout>
      <StyledContent>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card title="Details">
              <ul style={{listStyleType: 'none'}}>
                <li>
                  <strong>Partner ID:</strong> {me.userId}
                </li>
                <li>
                  <strong>Full name:</strong> {me.full_name}
                </li>
                <li>
                  <strong>Email:</strong> {me.email}
                </li>
                <li>
                  <a href="https://www.conversionbear.com/conversion-bear-partners-terms-and-conditions" target="_blank">Terms of Service</a>
                </li>
              </ul>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card title="Security">
              <ul style={{listStyleType: 'none'}}>
                <li>
                  <Link to={ROUTES.PASSWORD_CHANGE}>
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.EMAIL_CHANGE}>Change Email</Link>
                </li>
                <li>
                  <a href="/" onClick={(e) => {
                    e.preventDefault();
                    signOut();
                    amplitude.logEvent('click-sign_out');
                  }}>Log out</a>
                </li>
              </ul>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card title="Contact Us">
              <ul style={{listStyleType: 'none'}}>
                <li>
                  <Link to={'mailto: jonathan@conversionbear.com'}>jonathan@conversionbear.com</Link>
                </li>
                <li>
                  <Link to={'mailto: lynda@conversionbear.com'}>lynda@conversionbear.com</Link>
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      </StyledContent>
    </Layout>
  );
};

export default AccountPage;
