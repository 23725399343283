import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 30px 40px 10px 40px;
  background: white;
  @media (max-width: 768px) {
    padding: 30px 20px 0px 20px;
  }
`
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #212B36;
  margin-bottom: 30px;
`
const Block = styled.div`
  box-shadow: 0px 0px 10px rgba(196, 205, 213, 0.6);
  border-radius: 3px;
  padding: 20px;
  @media (max-width: 768px) {
    margin-bottom: 20px
  }
  .title{
    color: white;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  .text{
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: white;
  }
`;

const Item = styled.div`
  max-width: 204px;
  margin: 0 20px;
`

const Items = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
`;

class CommissionRewards extends Component {
  render() {
    return (
      <Container>
        <Title>Commission Rewards</Title>
        <Items>
          <Item>
            <Block style={{background: 'linear-gradient(122.11deg, #9CA6AF 0%, #AEC2D2 100%)'}}>
              <div className="title">$12/month</div>
              <div className="text">for every store with a Silver plan</div>
            </Block>
          </Item>
          <Item>
            <Block style={{background: 'linear-gradient(122.11deg, #FFD43D 0%, #FFC120 100%)'}}>
              <div className="title">$24/month</div>
              <div className="text">for every store with a Gold plan</div>
            </Block>
          </Item>
          <Item>
            <Block style={{background: 'linear-gradient(122.11deg, #D9C797 0%, #FFD568 100%)'}}>
              <div className="title">$35/month</div>
              <div className="text">for every store with a Platinum plan</div>
            </Block>
          </Item>
        </Items>
      </Container>
    )
  };
}

export default CommissionRewards;